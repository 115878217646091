import React, { ComponentType } from 'react'
import { UiComponent } from 'components/ui/types'

type SrcSet = [number, number]

type ImageProps = {
  src: string
  alt?: string
  objectFit?: 'contain' | 'cover'
  objectPosition?:
    | 'top'
    | 'bottom'
    | 'center'
    | 'left'
    | 'right'
    | 'left-bottom'
    | 'left-top'
    | 'right-bottom'
    | 'right-top'
  square?: boolean
  height?: number
  width?: number
  priority?: boolean
  srcSet?: SrcSet[]
  g?: string
} & UiComponent

export const maxImageWidth = 900
export const maxScreenSize = 1980
export const imageConvertedSrc = (
  src: string,
  width: number,
  g?: string
): string => {
  let computedSrc = `${src}?d=${width}x`
  if (src.includes('.png') && width > 150) {
    computedSrc = `${computedSrc}&f=jpeg`
  }
  if (g) {
    computedSrc = `${computedSrc}&g=${g}`
  }
  return computedSrc
}

const Image: ComponentType<ImageProps> = ({
  src,
  className = '',
  alt = '',
  objectFit = 'cover',
  objectPosition = 'center',
  square = false,
  width,
  height,
  priority = false,
  srcSet,
  g
}) => {
  // We map provided srcSizes to proper url and screen size of srcSet format
  // https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
  const calculatedSrcSet =
    srcSet && srcSet.length > 1
      ? srcSet
          .map((ss) => `${imageConvertedSrc(src, ss[0])} ${ss[1]}w`)
          .join(', ')
      : undefined
  // We limit image size to the biggest size we use
  let computedSrc = src
  // If we have only 1 item in srcSet, we dont need larger image
  if (srcSet && srcSet.length === 1) {
    computedSrc = imageConvertedSrc(src, srcSet[0][0], g)
  } else if (width) computedSrc = imageConvertedSrc(src, width, g)
  return (
    <div
      className={`image view-vertical relative ${className} ${
        square ? 'image-square' : ''
      }`}
    >
      <img
        src={computedSrc}
        alt={alt}
        className={`object-${objectFit} object-${objectPosition}`}
        srcSet={calculatedSrcSet}
        loading="eager"
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    </div>
  )
}

export default Image
