'use client'

import Button from 'components/ui/basic/Button'
import React, { ComponentType, ReactNode, useState } from 'react'
import {
  ArrowLeftCircle,
  ChevronRight,
  ExternalLink,
  // Search,
  User,
  X
} from 'react-feather'

import HandballNetLogo from 'public/img/handball-net-logo-red.svg'
import HBButton from 'components/ui/basic/HBButton'
import { useAppConfig } from 'data/config/app_config'
import {
  INavigationGroup,
  INavigationItem,
  INavigationLink
} from 'data/config/types'
import Link from 'next/link'
import { useSession } from 'lib/session-client'
import { myProfilePath } from 'shared/urlHelpers'
import { useUserProfile } from 'auth/useUserProfile'
import { StaticIcon } from './StaticIcon'
import { useMenu } from 'context/MenuContext'
// import { searchPath } from 'shared/urlHelpers'

interface SubMenuProps {
  subMenu: INavigationGroup
  setSubMenu: (value: null | INavigationGroup) => void
}

const SubMenu: ComponentType<SubMenuProps> = ({
  subMenu,
  setSubMenu
}: SubMenuProps) => {
  const containsNested =
    subMenu.items.filter((item) => 'items' in item).length > 0

  const flatItems = !containsNested
    ? subMenu.items.map((item: INavigationItem) => item as INavigationLink)
    : []

  return (
    <>
      <div
        className="fixed left-0 top-0 bottom-0 right-0 pl-16 md:max-w-md md:w-[50vw] md:pl-0 md:left-[min(50vw,28rem)] z-[9999999] bg-blue-dark/70 backdrop-blur-sm sub-menu-container md:backdrop-blur-none md:bg-transparent md:z-[8999999]"
        onClick={() => setSubMenu(null)}
      >
        <div className="bg-blue-dark text-white h-full sub-menu pt-24 px-8 flex flex-col">
          <div className="mt-2 -ml-4 sticky top-0">
            <Button
              color="transparent"
              className="!px-4 !m-0"
              onClick={() => setSubMenu(null)}
            >
              <ArrowLeftCircle />
            </Button>
          </div>
          <nav className="mt-9 flex-grow overflow-y-auto pb-8">
            {!containsNested && ( // flat navigation
              <ul className="flex flex-col gap-px">
                {flatItems.map((item) => (
                  <li key={`main_navigation.sub_menu.${item.id}`}>
                    <a
                      href={item.href}
                      target={item.target}
                      title={item.title}
                      className="flex gap-2 w-full py-2.5 md:py-1 text-lg"
                    >
                      {item.name}
                      {item.target === '_blank' ? (
                        <ExternalLink className="inline-block w-4 shrink-0 -mt-0.5" />
                      ) : (
                        ''
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            )}

            {containsNested && ( // nested navigation
              <ul className="flex flex-col gap-px">
                {subMenu.items.map((item) => (
                  <li
                    key={`main_navigation.sub_menu.${item.id}`}
                    className="mt-2 pt-4 border-t border-gray-300 first:mt-0 first:pt-0 first:border-t-0"
                  >
                    {'items' in item && ( // is a nested INavigationGroup}
                      <>
                        <strong className="text-lg">{item.name}</strong>
                        <ul className="flex flex-col gap-px mt-2">
                          {item.items.map((subItem) => (
                            <li key={`main_navigation.sub_menu.${subItem.id}`}>
                              {'href' in subItem && ( // is a INavigationLink}
                                <a
                                  href={subItem.href}
                                  target={subItem.target}
                                  title={subItem.title}
                                  className="flex gap-2 w-full py-2.5 md:py-1 text-lg"
                                >
                                  {subItem.name}
                                  {subItem.target === '_blank' ? (
                                    <ExternalLink className="inline-block w-4 shrink-0 -mt-0.5" />
                                  ) : (
                                    ''
                                  )}
                                </a>
                              )}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}

                    {'href' in item && ( // is a INavigationLink}
                      <a
                        href={item.href}
                        target={item.target}
                        title={item.title}
                        className="flex gap-2 w-full py-2.5 md:py-1 text-lg"
                      >
                        {item.name}
                        {item.target === '_blank' ? (
                          <ExternalLink className="inline-block w-4 shrink-0 -mt-0.5" />
                        ) : (
                          ''
                        )}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </nav>
        </div>
      </div>
    </>
  )
}

const Menu: ComponentType = () => {
  const { main_navigation: navigation } = useAppConfig()
  const [subMenu, setSubMenu] = useState<null | INavigationGroup>(null)
  const { menuOpen, closeMenu } = useMenu()

  const [session] = useSession()
  const { userProfile } = useUserProfile(false)

  if (!menuOpen) {
    return
  }

  return (
    <>
      <div
        className="fixed top-0 right-0 bottom-0 left-0 bg-blue-dark/70 z-[8999999] backdrop-blur-sm main-menu-backdrop"
        onClick={closeMenu}
      ></div>
      <div className="fixed left-0 top-0 bottom-0 right-0 md:right-auto md:w-[50vw] md:max-w-md w-full bg-white z-[9999999] py-8 px-8 main-menu flex flex-col">
        <div className="flex gap-4 justify-between items-center pb-4">
          <div className="flex-grow">
            <HandballNetLogo className="aspect-[280/29] max-w-[17.5rem]" />
          </div>
          <div className="-mr-4">
            <Button color="transparent" className="!px-4" onClick={closeMenu}>
              <X />
            </Button>
          </div>
        </div>

        {/* scrolling content */}
        <div className="flex-grow min-h-0 overflow-y-auto pb-8">
          {/* Register button & search */}
          <div className="grid grid-cols-1 gap-4 mt-4">
            <div>
              {!session && (
                <HBButton className="h-full !inline-flex">
                  <User className="mr-1" />
                  Anmelden
                </HBButton>
              )}
              {session && (
                <div className="">
                  <Link
                    href={myProfilePath()}
                    className="font-bold  hover:text-bodyColor  active:text-body text-primary inline-flex items-center whitespace-nowrap gap-1.5"
                  >
                    <User className="w-4 h-4 shrink-0 -mt-1" />
                    <span className="min-w-0 overflow-hidden text-ellipsis">
                      {userProfile?.firstname}
                    </span>
                  </Link>
                  <div className="text-sm text-gray-500 ">
                    <Link
                      className="hover:text-primary active:text-primary-dark"
                      href={`${myProfilePath()}`}
                    >
                      Meine Teams & Ligen
                    </Link>
                    {/* &middot;{' '}
                    <Link
                      className="hover:text-primary active:text-primary-dark"
                      href={`${myProfilePath()}#ligen-a`}
                    >
                      Meine Ligen
                    </Link> */}
                  </div>
                </div>
              )}
            </div>
            {/* <Link
              href={searchPath()}
              className="flex !justify-start gap-1 btn btn-gray !px-4"
            >
              <Search className="w-5" />
              <span className="">Suchen</span>
            </Link> */}
          </div>

          {/* Main Menu */}
          <nav className="mt-8">
            <ul className="flex flex-col gap-px">
              {navigation.main_items.map((item) => (
                <li
                  key={`main_navigation.main_items.${item.id}`}
                  className="mb-0"
                >
                  <Link
                    className="flex gap-2 w-full text-left py-2.5 md:py-1 uppercase text-lg font-bold hover:text-primary active:text-bodyColor"
                    onClick={closeMenu}
                    href={item.href || '#'}
                    target={item.target}
                    title={item.title}
                  >
                    {item.name}
                    {item.target === '_blank' ? (
                      <ExternalLink className="inline-block w-4 shrink-0 -mt-0.5" />
                    ) : (
                      ''
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          {/* SecondaryMenu */}
          <nav className="mt-4 pt-4 border-t border-gray-300">
            <ul>
              {navigation.secondary_items.map((item) => (
                <li key={`main_navigation.secondary_items.${item.id}`}>
                  {'items' in item && (
                    <SecondaryMenuButton onClick={() => setSubMenu(item)}>
                      {item.name}
                      <ChevronRight className="w-5" />
                    </SecondaryMenuButton>
                  )}
                  {'href' in item && (
                    <Link
                      onClick={closeMenu}
                      className="flex gap-2 w-full text-left py-2.5 md:py-1 text-lg hover:text-primary active:text-bodyColor"
                      href={item.href || '#'}
                      target={item.target}
                      title={item.title}
                    >
                      {item.name}
                      {item.target === '_blank' ? (
                        <ExternalLink className="inline-block w-4 shrink-0 -mt-0.5" />
                      ) : (
                        ''
                      )}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </nav>

          {/* InfoMenu */}
          <nav className="mt-4 pt-4 border-t border-gray-300">
            <ul>
              {navigation.info_items.map((item) => (
                <li key={`main_navigation.info_items.${item.id}`}>
                  {'items' in item && (
                    <SecondaryMenuButton onClick={() => setSubMenu(item)}>
                      {item.name}
                      <ChevronRight className="w-5" />
                    </SecondaryMenuButton>
                  )}
                  {'href' in item && (
                    <Link
                      onClick={closeMenu}
                      className="flex gap-2 w-full text-left py-2.5 md:py-1 text-lg hover:text-primary active:text-bodyColor"
                      href={item.href || '#'}
                      target={item.target}
                      title={item.title}
                    >
                      {item.name}
                      {item.target === '_blank' ? (
                        <ExternalLink className="inline-block w-4 shrink-0 -mt-0.5" />
                      ) : (
                        ''
                      )}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>

        {/* Social Icons */}
        <div className="mt-6">
          <ul className="flex gap-1 -mx-3">
            {navigation.social_items.map((item) => (
              <li
                key={`main_navigation.social_items.${item.id || item.name}`}
                className=""
              >
                <Link
                  onClick={closeMenu}
                  className="w-12 flex items-center justify-center"
                  href={item.href || '#'}
                  target={item.target}
                  title={item.title}
                >
                  {item.icon &&
                    'id' in item.icon && ( // is a static icon
                      <StaticIcon icon={item.icon} className="w-5 h-5 block" />
                    )}

                  {item.icon &&
                    'url' in item.icon && ( // is a dynamic icon
                      <img
                        src={item.icon.url}
                        alt={item.name}
                        className="w-5 h-5 block"
                      />
                    )}

                  {!item.icon && item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {subMenu && <SubMenu subMenu={subMenu} setSubMenu={setSubMenu} />}
    </>
  )
}

export default Menu

const SecondaryMenuButton: ComponentType<{
  onClick: () => void
  children: ReactNode
}> = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      className="py-2.5 md:py-1 text-lg flex gap-2 w-full hover:text-primary active:text-bodyColor"
    >
      {children}
    </button>
  )
}
