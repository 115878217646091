// 'use client'

import {
  Dispatch,
  Ref,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import throttle from 'lodash/throttle'
// import { useRouter } from 'next/router'

// eslint-disable-next-line no-undef
export const onClickOutside = <T extends Element>(
  handle: () => void
): Ref<T> => {
  const node = useRef<T>(null)

  // eslint-disable-next-line no-undef
  const handleClick = (e: MouseEvent) => {
    const target = e.target as T
    if (!node.current?.contains(target)) {
      handle()
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])
  return node
}

export function useNonRerenderState<T>(val: T): [{ value: T }, (t: T) => void] {
  const holder = useMemo(() => ({ value: val }), [])
  return [
    holder,
    (val: T) => {
      holder.value = val
    }
  ]
}

// Improved useState hook which detects, if component is mounted, so it wont try to call set function on unmounted comp
export function useMountedState<S>(
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>] {
  const [isMounted, setIsMounted] = useNonRerenderState(false)
  const [val, setVal] = useState(initialState)
  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])
  const setValIfMounted: Dispatch<SetStateAction<S>> = (v) => {
    if (isMounted.value) setVal(v)
  }
  return [val, setValIfMounted]
}

export const useWindowResize = (
  handle: (width: number, height: number) => void
): void => {
  useEffect(() => {
    const listener = () => handle(window.innerWidth, window.innerHeight)
    window.addEventListener('resize', throttle(listener, 300))

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])
}

enum Sizes {
  xs = 360,
  sm = 480,
  md = 767,
  lg = 1024,
  xl = 1600
}

export const ScreenSize = {
  Sizes,
  isTabletScreen: (size: number): boolean => size <= Sizes.md
}

export const isTabletScreen = (): boolean => {
  const [v, setV] = useMountedState(false)
  useEffect(() => setV(ScreenSize.isTabletScreen(window.innerWidth)), [])

  useWindowResize((w) => setV(ScreenSize.isTabletScreen(w)))

  return v
}

export const isScreenSize = (max: number): boolean => {
  const isSize = (current: number) => current <= max

  const [v, setV] = useMountedState(false)
  useEffect(() => setV(ScreenSize.isTabletScreen(window.innerWidth)), [])

  useWindowResize((w) => setV(isSize(w)))

  return v
}
