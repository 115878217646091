import { ReactNode, ComponentType } from 'react'
import {
  Home,
  List,
  MoreHorizontal,
  User,
  IconProps
  // Facebook,
  // Instagram,
  // X
} from 'react-feather'
import Goal from 'public/img/layout/tab-bar-spiele.svg'
import { IStaticIcon } from 'data/config/types'
import FacebookIcon from 'public/img/social-media-icons/facebook.svg'
import InstagramIcon from 'public/img/social-media-icons/instagram.svg'
import XIcon from 'public/img/social-media-icons/x.svg'
import YoutubeIcon from 'public/img/social-media-icons/youtube.svg'
import TikTokIcon from 'public/img/social-media-icons/tiktok.svg'

const iconMapping: {
  [key: string]: ComponentType<IconProps & { className?: string }>
} = {
  Home: Home,
  Goal: Goal as ComponentType<IconProps & { className?: string }>,
  List: List,
  User: User,
  MoreHorizontal: MoreHorizontal,
  // Facebook: Facebook,
  // Instagram: Instagram,
  // X: X,
  Facebook: FacebookIcon as ComponentType<IconProps & { className?: string }>,
  Instagram: InstagramIcon as ComponentType<IconProps & { className?: string }>,
  X: XIcon as ComponentType<IconProps & { className?: string }>,
  Youtube: YoutubeIcon as ComponentType<IconProps & { className?: string }>,
  TikTok: TikTokIcon as ComponentType<IconProps & { className?: string }>
}

export const StaticIcon = ({
  icon,
  className
}: {
  icon: IStaticIcon
  className?: string
}): ReactNode => {
  const IconComponent = iconMapping[icon.id]
  return IconComponent ? <IconComponent className={className} /> : null
}
