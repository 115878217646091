import { Transition } from '@headlessui/react'
import { Heading } from 'components/ui/text/Heading'
import { ComponentType, ReactNode, useState } from 'react'
import { X } from 'react-feather'

interface OverlayProps {
  trigger?: ReactNode
  small?: boolean
  title?: string
  overflow?: boolean
  borderless?: boolean
  children: ReactNode
}

export const ControlledOverlay: ComponentType<
  OverlayProps & {
    overlayIsOpen: boolean
    setOverlayIsOpen: (value: boolean) => void
    showTrigger?: boolean
    inlineOverlay?: boolean
  }
> = ({
  trigger,
  children,
  title,
  overlayIsOpen,
  setOverlayIsOpen,
  small = false,
  overflow = false,
  borderless = false,
  showTrigger = true,
  inlineOverlay = false
}) => {
  return (
    <>
      {showTrigger && trigger}
      <Transition show={overlayIsOpen}>
        <div
          className={`overlay ${inlineOverlay ? "overlay--inline" : ""} ${small ? 'small' : ''} ${
            borderless ? 'overlay-borderless' : ''
          }`}
        >
          <div className="overlay-wrapper">
            <div className="overlay-backdrop-wrapper" aria-hidden="true">
              <div
                className="overlay-backdrop overlay-backdrop-red"
                onClick={() => setOverlayIsOpen(!overlayIsOpen)}
              />
            </div>
            <div
              className="overlay-content-wrapper"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="overlay-header">
                {title ? <Heading level={3}>{title}</Heading> : <span></span>}

                <span
                  onClick={() => setOverlayIsOpen(!overlayIsOpen)}
                  color="transparent"
                  className="overlay-close"
                >
                  <X />
                </span>
              </div>
              <div
                className={`overlay-content ${
                  overflow ? 'overlay-content-overflow' : ''
                }`}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  )
}

const Overlay: ComponentType<OverlayProps> = (props) => {
  const [overlayIsOpen, setOverlayIsOpen] = useState(false)

  return (
    <>
      <span onClick={() => setOverlayIsOpen(!overlayIsOpen)}>
        {props.trigger}
      </span>
      <ControlledOverlay
        {...props}
        showTrigger={false}
        overlayIsOpen={overlayIsOpen}
        setOverlayIsOpen={setOverlayIsOpen}
      />
    </>
  )
}

export default Overlay

export const OverlayWithoutTrigger: ComponentType<OverlayProps> = ({
  children,
  title,
  small = false,
  overflow = false,
  borderless = false
}) => {
  const [overlayIsOpen, setOverlayIsOpen] = useState(true)
  return overlayIsOpen ? (
    <div
      className={`overlay ${small ? 'small' : ''} ${
        borderless ? 'overlay-borderless' : ''
      }`}
    >
      <div className="overlay-wrapper">
        <div className="overlay-backdrop-wrapper" aria-hidden="true">
          <div
            className="overlay-backdrop overlay-backdrop-red"
            onClick={() => setOverlayIsOpen(!overlayIsOpen)}
          />
        </div>
        <div
          className="overlay-content-wrapper"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="overlay-header">
            {title ? <Heading level={3}>{title}</Heading> : <span></span>}

            <span
              onClick={() => setOverlayIsOpen(!overlayIsOpen)}
              color="transparent"
              className="overlay-close"
            >
              <X />
            </span>
          </div>
          <div
            className={`overlay-content ${
              overflow ? 'overlay-content-overflow' : ''
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : null
}
