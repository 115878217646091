// Available pageIds as per Excel, checked at 28.05.2024
// Extended with olympia ids at 18.07.24

export const adDataStructure = {
  // default
  default: {
    adPageIds: {
      default: '1840030'
    }
  },

  // pages
  'mein-profil': {
    adPageIds: {
      default: '1841653'
    }
  },
  'profil-bearbeiten': {
    adPageIds: {
      default: '1841653'
    }
  },

  vereine: {
    adPageIds: {
      default: '1841624'
    }
  },
  hallen: {
    adPageIds: {
      default: '1841625'
    }
  },
  verbaende: {
    adPageIds: {
      default: '1841626'
    }
  },
  fanshops: {
    adPageIds: {
      default: '1841612'
    }
  },
  ticketshops: {
    adPageIds: {
      default: '1841613'
    }
  },
  suche: {
    adPageIds: {
      default: '1841614'
    }
  },
  'spiele-des-tages': {
    adPageIds: {
      default: '1841616'
    }
  },
  'warum-registrieren': {
    adPageIds: {
      default: '1841617'
    }
  },
  'ernaehrungs-blog': {
    adPageIds: {
      default: '1841618'
    }
  },
  gesundheitsblog: {
    adPageIds: {
      default: '1841619'
    }
  },
  widgets: {
    name: 'widgets',
    adPageIds: {
      default: '1841623'
    }
  },

  // news
  news: {
    adPageIds: {
      default: '1841488'
    },
    u21: {
      adPageIds: {
        default: '1841518'
      }
    },
    beachhandball: {
      adPageIds: {
        default: '1841519'
      }
    },
    verbaende: {
      adPageIds: {
        default: '1841520'
      }
    },
    'so-tech-cup': {
      adPageIds: {
        default: '1841620'
      }
    },
    adventskalender: {
      adPageIds: {
        default: '1911700'
      }
    },
    'Frauen-WM-2023': {
      adPageIds: {
        default: '191413'
      }
    },
    'amateurtor-des-monats': {
      adPageIds: {
        default: '1932691'
      }
    },
    'hero-der-woche': {
      adPageIds: {
        default: '1860890'
      }
    },
    'hero-des-monats': {
      adPageIds: {
        default: '1945301'
      }
    },
    'frauen-ww': {
      adPageIds: {
        default: '1914132'
      }
    },
    hbl: {
      adPageIds: {
        default: '1841547'
      }
    },
    hbf: {
      adPageIds: {
        default: '1841569'
      }
    },
    '2-hbl': {
      adPageIds: {
        default: '1841555'
      }
    },
    '2-hbf': {
      adPageIds: {
        default: '1841575'
      }
    },
    '3-liga-manner': {
      adPageIds: {
        default: '1841583'
      }
    },
    '3-liga-frauen': {
      adPageIds: {
        default: '1841590'
      }
    },
    jugendbundesliga: {
      adPageIds: {
        default: '1841597'
      }
    },
    amateure: {
      adPageIds: {
        default: '1841605'
      }
    },
    supercup: {
      adPageIds: {
        default: '1841562'
      }
    },
    amateurpokal: {
      adPageIds: {
        default: '1841498'
      }
    },
    'german-international-youth-championships': {
      adPageIds: {
        default: '1841512'
      }
    },
    'ehf-euro-2024': {
      adPageIds: {
        default: '1841505'
      }
    },
    'olympia-2024': {
      adPageIds: {
        default: '1997812'
      }
    }
  },

  // tournaments
  wettbewerbe: {
    adPageIds: {
      default: '1841489'
    },
    'sid.ihf.wm': {
      // IHF Weltmeisterschaft
      adPageIds: {
        news: '1841491',
        schedule: '1841492',
        game: '1841493',
        'all-games': '1841494',
        table: '1841495',
        statistics: '1841496'
      }
    },
    'sportradar.dhbdata.6920': {
      // Deutscher Amateur Pokal
      adPageIds: {
        news: '1841498',
        schedule: '1841499',
        game: '1841500',
        'all-games': '1841501',
        table: '1841502',
        statistics: '1841503'
      }
    },
    'sportradar.dhbdata.14809': {
      // EHF Euro Cup
      adPageIds: {
        news: '1841505',
        schedule: '1841506',
        game: '1841507',
        'all-games': '1841508',
        table: '1841509',
        statistics: '1841510'
      }
    },
    'sr.competition.434': {
      // Pixum Super Cup
      adPageIds: {
        news: '1841562',
        schedule: '1841563',
        game: '1841564',
        'all-games': '1841565',
        statistics: '1841566'
      }
    },
    'sportradar.dhbdata.6071': {
      // German International Youth Championships
      adPageIds: {
        news: '1841512',
        schedule: '1841513',
        game: '1841514',
        'all-games': '1841515',
        table: '1841516',
        statistics: '1841517'
      }
    },
    'sportradar.dhbdata.1080': {
      // Länderspiele
      adPageIds: {
        news: '1841522',
        schedule: '1841524',
        game: '1841527',
        'all-games': '1841528',
        statistics: '1841529'
      }
    },
    'sportradar.dhbdata.5315': {
      // Vier-Länder-Turnier
      adPageIds: {
        news: '1841531',
        schedule: '1841532',
        game: '1841533',
        'all-games': '1841534',
        table: '1841535',
        statistics: '1841536'
      }
    },
    'sid.ihf.olympia_m': {
      // Olympia Männer
      adPageIds: {
        news: '1997812',
        schedule: '1997813',
        game: '1997814',
        'all-games': '1997815',
        table: '1997816',
        statistics: '1997817'
      }
    },
    'sid.ihf.olympia_w': {
      // Olympia Frauen
      adPageIds: {
        news: '1997812',
        schedule: '1997813',
        game: '1997814',
        'all-games': '1997815',
        table: '1997816',
        statistics: '1997817'
      }
    }
    // TODO : this mapping is currently meaningless
    // 'Amateur Wettbewerbe': {
    //   adPageIds: {
    //     // news: '1841538',
    //     schedule: '1841539',
    //     game: '1841540',
    //     'all-games': '1841541',
    //     table: '1841542',
    //     statistics: '1841543'
    //   }
    // }
  },

  // leagues
  ligen: {
    adPageIds: {
      default: '1841545'
    },
    'sr.competition.149': {
      // LIQUI MOLY HBL
      adPageIds: {
        news: '1841547',
        schedule: '1841548',
        game: '1841549',
        'all-games': '1841550',
        table: '1841551',
        statistics: '1841552'
      }
    },
    'sr.competition.921': {
      // 2. HBL
      adPageIds: {
        news: '1841555',
        schedule: '1841556',
        game: '1841557',
        'all-games': '1841558',
        table: '1841559',
        statistics: '1841560'
      }
    },
    'sr.competition.434': {
      // Pixum Super Cup
      adPageIds: {
        news: '1841562',
        schedule: '1841563',
        game: '1841564',
        'all-games': '1841565',
        statistics: '1841566'
      }
    },
    'sportradar.hbf.228': {
      // 1. Bundesliga Frauen
      adPageIds: {
        news: '1841569',
        schedule: '1841570',
        game: '1841571',
        'all-games': '1841572',
        table: '1841580',
        statistics: '1841573'
      }
    },
    'sportradar.hbf.312': {
      // 2. Bundesliga Frauen
      adPageIds: {
        news: '1841575',
        schedule: '1841576',
        game: '1841577',
        'all-games': '1841578',
        table: '1841579',
        statistics: '1841581'
      }
    },
    'sportradar.dhbdata.16059': {
      // 3. Liga Männer
      adPageIds: {
        news: '1841583',
        schedule: '1841584',
        game: '1841585',
        // liveStreaming: '1841652', // not on handball.net
        'all-games': '1841586',
        table: '1841587',
        statistics: '1841588'
      }
    },
    'sportradar.dhbdata.16060': {
      // 3. Liga Frauen
      adPageIds: {
        news: '1841590',
        schedule: '1841591',
        game: '1841592',
        // liveStreaming: '1841651', // not on handball.net
        'all-games': '1841593',
        table: '1841594',
        statistics: '1841595'
      }
    }
    // TODO : those mappings currentlu are meaningless
    // Jugendbundesliga: { // Jugendbundesliga
    //   adPageIds: {
    //     news: '1841597',
    //     schedule: '1841598',
    //     game: '1841599',
    //     'all-games': '1841600',
    //     table: '1841601',
    //     statistics: '1841602'
    //   }
    // },
    // Amateure: { // Amateure
    //   adPageIds: {
    //     news: '1841605',
    //     schedule: '1841606',
    //     game: '1841607',
    //     'all-games': '1841608',
    //     table: '1841609',
    //     statistics: '1841610'
    //   }
    // }
  }
}
