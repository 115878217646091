'use client'

import { ReactNode } from 'react'
import Link from 'next/link'
import { loginPath, myProfilePath } from 'shared/urlHelpers'
import { useSession } from 'lib/session-client'
import { INavigationLink } from 'data/config/types'
import { StaticIcon } from './StaticIcon'
import { useMenu } from 'context/MenuContext'
import { routeActive } from './NewTabBar'

export const NewTabNavigationItem = ({
  item
}: {
  item: INavigationLink
}): ReactNode => {
  const [session] = useSession()
  const { openMenu } = useMenu()

  // TODO:Benni tab bar aktivzustand funktioniert aktuell nicht

  if (item.action) {
    const href =
      item.action === 'open-profile'
        ? session
          ? myProfilePath()
          : loginPath()
        : item.href
    const onClick = item.action === 'open-menu' ? () => openMenu() : undefined

    return (
      <li className="flex-grow">
        <Link
          className={`group flex items-center flex-col pt-1 pb-1`}
          href={href || '#'}
          target={item.target}
          onClick={onClick}
          title={item.title}
        >
          <div className="flex flex-col items-center gap-1.5 rounded-full aspect-1 py-3 group-active:bg-primary group-active:text-white">
            {item.icon &&
              'id' in item.icon && ( // is a static icon
                <StaticIcon icon={item.icon} className="w-7 h-7" />
              )}
            <span className="uppercase text-xs">{item.name}</span>
          </div>
        </Link>
      </li>
    )
  }

  if (item.href) {
    return (
      <li className="flex-grow">
        <Link
          className={`group flex items-center flex-col pt-1 pb-1 ${
            routeActive(item.href) && 'text-primary'
          }`}
          href={item.href}
          target={item.target}
          title={item.title}
        >
          <div className="flex flex-col items-center gap-1.5 rounded-full aspect-1 py-3 group-active:bg-primary group-active:text-white">
            {item.icon &&
              'id' in item.icon && ( // is a static icon
                <StaticIcon icon={item.icon} className="w-7 h-7" />
              )}
            <span className="uppercase text-xs">{item.name}</span>
          </div>
        </Link>
      </li>
    )
  }

  return null
}
