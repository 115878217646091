import { useMemo } from 'react'
import useSWR, { mutate } from 'swr'

export interface Session {
  id: number
  accessToken: string

  // we never need the payload, ignore it, for better memoization
  // payload: any
}

const KEY_SESSION = 'SESSION'

const sessionFetcher = async () => {
  // eslint-disable-next-line no-undef
  const result = await fetch('/api/auth/session', {
    method: 'POST'
  })

  if (result.ok) {
    return await result.json()
  } else {
    throw new Error('Failed to fetch session')
  }
}

export function useSession(): [Session | undefined, boolean] {
  const { data, error } = useSWR<Session, Error>(KEY_SESSION, sessionFetcher, {
    refreshInterval: 5 * 60 * 1000, // 5 minutes
    focusThrottleInterval: 60 * 1000 // 1 minute
  })

  return useMemo(() => {
    if (data) {
      if (data.id === 0) {
        return [undefined, false]
      } else {
        return [data, false]
      }
    } else {
      return [undefined, !error]
    }
  }, [data?.id, data?.accessToken, error])
}

const invalidLoginCredentialsError = 'Ungültige Logindaten'

export async function signIn(
  username: string,
  password: string
): Promise<Session> {
  // eslint-disable-next-line no-undef
  const result = await fetch('/api/auth/signIn', {
    method: 'POST',
    body: JSON.stringify({ username, password })
  })

  if (result.ok) {
    await mutate(KEY_SESSION)
    return (await result.json()) as Session
  } else {
    const body = await result.json()
    if (body.reason === 'VerifyEmailPending' && body.jwt) {
      // eslint-disable-next-line no-throw-literal
      throw {
        jwt: body.jwt,
        message: invalidLoginCredentialsError
      }
    } else {
      throw new Error(invalidLoginCredentialsError)
    }
  }
}

export async function signOut(): Promise<void> {
  // eslint-disable-next-line no-undef
  const result = await fetch('/api/auth/signOut', {
    method: 'POST'
  })

  if (result.ok) {
    await mutate(KEY_SESSION)
  } else {
    throw new Error('Sign out failed')
  }
}
