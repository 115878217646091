'use client'

import { useMenu } from 'context/MenuContext'
import { ReactElement } from 'react'
import { Menu as MenuIcon } from 'react-feather'

const NewHeaderMenuButton = (): ReactElement => {
  const { openMenu } = useMenu()

  return (
    <button
      className="hidden lg:flex px-4 mr-2 -ml-4 h-12 items-center hover:text-primary active:text-primary-dark"
      onClick={openMenu}
    >
      <MenuIcon />
    </button>
  )
}

export default NewHeaderMenuButton
