import { useRouter } from 'next/navigation'
import React, { useRef, useState } from 'react'
import {
  clubPath,
  fieldPath,
  leagueSchedulePathById,
  searchPath,
  teamPath,
  tournamentSchedulePathById
} from 'shared/urlHelpers'

interface Option {
  value: string
  label: string
  type?: string // 'Field' | 'Club' | 'Tournament' | 'League' | 'Team'
  url?: string
  logo?: string
}

interface UseAutoCompleteProps {
  delay?: number
  source: (searchTerm: string) => Promise<Option[]>
  setIsOpen: (value: boolean) => void
  // onChange: (option: Option) => void
}

interface AutoCompleteHook {
  bindOption: {
    onClick: (event: React.MouseEvent<HTMLLIElement>) => void
  }
  bindInput: {
    value: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void
  }
  bindOptions: {
    ref: React.RefObject<HTMLUListElement>
  }
  isBusy: boolean
  suggestions: Option[]
  selectedIndex: number
  setTextValue: (value: string) => void
}

const useAutoComplete = ({
  delay = 500,
  source,
  setIsOpen
}: UseAutoCompleteProps): AutoCompleteHook => {
  const router = useRouter()
  const [myTimeout, setMyTimeout] = useState<NodeJS.Timeout | null>(null)
  const listRef = useRef<HTMLUListElement>(null)
  const [suggestions, setSuggestions] = useState<Option[]>([])
  const [isBusy, setBusy] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [textValue, setTextValue] = useState('')

  const delayInvoke = (cb: () => void) => {
    if (myTimeout) {
      clearTimeout(myTimeout)
    }
    setMyTimeout(setTimeout(cb, delay))
  }

  const selectOption = (index: number) => {
    if (index > -1 && index < suggestions.length) {
      // onChange(suggestions[index])
      setTextValue(suggestions[index].label)
      // clearSuggestions()
    }
  }

  const redirectToSearch = (index?: number) => {
    const i = index ?? selectedIndex

    if (i > -1) {
      setBusy(true)
      selectOption(i)
      const selected = suggestions[i]

      setIsOpen(false)
      router.push(getUrlForSuggestion(selected))
      setBusy(false)
    } else if (textValue) {
      setBusy(true)
      setIsOpen(false)
      router.push(searchPath({ q: textValue }))
      setBusy(false)
    }
  }

  const clearSuggestions = () => {
    setSuggestions([])
    setSelectedIndex(-1)
  }

  const onTextChange = (searchTerm: string) => {
    setBusy(true)
    setIsOpen(true)
    setTextValue(searchTerm)
    clearSuggestions()
    delayInvoke(async () => {
      if (searchTerm) {
        const options = await source(searchTerm)
        setSuggestions(options)
        setBusy(false)
      }
    })
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault() // Prevent the cursor from moving in the input field
        selectOption(
          selectedIndex < suggestions.length - 1
            ? selectedIndex + 1
            : selectedIndex
        )
        setSelectedIndex((prev) =>
          prev < suggestions.length - 1 ? prev + 1 : prev
        )
        setTimeout(() => scrollBehavior(selectedIndex), 1)
        break
      case 'ArrowUp':
        e.preventDefault() // Prevent the cursor from moving in the input field
        selectOption(selectedIndex > 0 ? selectedIndex - 1 : selectedIndex)
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev))
        setTimeout(() => scrollBehavior(selectedIndex), 1)
        break
      case 'Enter':
        e.preventDefault()
        redirectToSearch()
        break
      case 'Escape':
        clearSuggestions()
        break
      case 'PageDown':
      case 'PageUp':
        // Optional: implement if needed
        break
    }
  }

  const scrollBehavior = (index: number) => {
    if (listRef.current && index >= 0 && suggestions.length > 0) {
      const selectedElement = listRef.current.children[index] as HTMLElement
      selectedElement.scrollIntoView({ block: 'nearest' })
    }
  }

  return {
    bindOption: {
      onClick: (e: React.MouseEvent<HTMLLIElement>) => {
        e.preventDefault()
        const index = Array.from(listRef.current?.children || []).indexOf(
          e.currentTarget
        )
        redirectToSearch(index)
      }
    },
    bindInput: {
      value: textValue,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        onTextChange(e.target.value),
      onKeyDown
    },
    bindOptions: {
      ref: listRef
    },
    isBusy,
    suggestions,
    selectedIndex,
    setTextValue
  }
}

export default useAutoComplete

function getUrlForSuggestion(suggestion: Option): string {
  if (suggestion.url) {
    if (suggestion.type === 'Field') {
      return fieldPath(suggestion.url)
    }
    if (suggestion.type === 'Club') {
      return clubPath(suggestion.url)
    }
    if (suggestion.type === 'Team') {
      return teamPath(suggestion.url)
    }
    if (suggestion.type === 'Tournament') {
      return tournamentSchedulePathById(suggestion.url)
    }
    if (suggestion.type === 'League') {
      return leagueSchedulePathById(suggestion.url)
    }
  }

  return searchPath({ q: suggestion.value })
}
