'use client'

import LinkButton from 'components/router/LinkButton'
import Button from 'components/ui/basic/Button'
import useTimeout from 'lib/useTimeout'
import React, { ComponentType, useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { appStorePath, googlePlayPath } from 'shared/urlHelpers'
import useLocalStorage from 'use-local-storage'
import { OverlayWithoutTrigger } from './Overlay'

// must be updated with new app releases
const androidTargetVersion = '1.0.3'
const iosTargetVersion = '1.0.3'

// ignore duration
const sevenDays = 7 * 24 * 60 * 60 * 1000

declare global {
  interface Window {
    appVersion: any
  }
}

const compareSemver = (value: string, target: string) => {
  let i, cmp
  const a = (value + '').split('.')
  const b = (target + '').split('.')
  const len = Math.max(a.length, b.length)

  for (i = 0; i < len; i++) {
    if (a[i] === undefined) {
      a[i] = '0'
    }
    if (b[i] === undefined) {
      b[i] = '0'
    }
    cmp = parseInt(a[i], 10) - parseInt(b[i], 10)
    if (cmp !== 0) {
      return cmp < 0 ? -1 : 1
    }
  }

  return 0
}

interface Acknowledged {
  version: string
  acknowledgedAt: Date
}

const AppUpdateInfo: ComponentType = () => {
  const [showUpdateInfo, setShowUpdateInfo] = useState<string | null>(null)
  const [acknowledged, setAcknowledged] = useLocalStorage<Acknowledged | null>(
    'AppUpdateInfo.acknowledgedAt',
    null
  )

  useTimeout(() => {
    // for testing
    // window.nativePushStatus = 'unsupported'
    // window.appVersion = '1.0.1'

    if (window.nativePushStatus) {
      // we have our native push interface, so we are in our app
      const targetVersion = isAndroid ? androidTargetVersion : iosTargetVersion
      if (window.appVersion) {
        let appVersion
        try {
          appVersion = window.appVersion()
        } catch (_error) {
          appVersion = window.appVersion.toString()
        }
        if (compareSemver(appVersion, targetVersion) < 0) {
          setShowUpdateInfo(appVersion)
        }
      } else {
        // is a older app (without version interface), always show info
        setShowUpdateInfo('<' + targetVersion)
      }
    }
  }, 1000)

  const acknowledge = () => {
    if (isIOS) {
      setAcknowledged({
        version: iosTargetVersion,
        acknowledgedAt: new Date()
      })
    }
    if (isAndroid) {
      setAcknowledged({
        version: androidTargetVersion,
        acknowledgedAt: new Date()
      })
    }
  }

  const isAcknowledged = (version: string) => {
    if (acknowledged && acknowledged.version === version) {
      const diff =
        new Date().getTime() - new Date(acknowledged.acknowledgedAt).getTime()

      return diff < sevenDays
    }

    return false
  }

  let info = null

  if (showUpdateInfo) {
    if (isAndroid) {
      if (!isAcknowledged(androidTargetVersion)) {
        info = (
          <OverlayWithoutTrigger title="" small>
            <div className="app-update-info">
              <p className="aui-text">
                Du nutzt aktuell noch eine alte Version ({showUpdateInfo}) von
                handball.net. Aktualisiere jetzt deine App (
                {androidTargetVersion}) , um von allen neuen Features zu
                profitieren.
              </p>

              <div className="btn-group">
                <LinkButton href={googlePlayPath()}>Zum Play Store</LinkButton>
                <Button
                  onClick={acknowledge}
                  small
                  color="transparent"
                  className="underline"
                >
                  Version Ignorieren
                </Button>
              </div>
            </div>
          </OverlayWithoutTrigger>
        )
      }
    } else {
      // isIOS (user agent unclear)
      if (!isAcknowledged(iosTargetVersion)) {
        info = (
          <OverlayWithoutTrigger title="" small>
            <div className="app-update-info">
              <p className="aui-text">
                Du nutzt aktuell noch eine alte Version ({showUpdateInfo}) von
                handball.net. Aktualisiere jetzt deine App ({iosTargetVersion}),
                um von allen neuen Features zu profitieren.
              </p>

              <div className="btn-group">
                <LinkButton href={appStorePath()} small>
                  Zum App Store
                </LinkButton>
                <Button
                  onClick={acknowledge}
                  small
                  color="transparent"
                  className="underline"
                >
                  Version Ignorieren
                </Button>
              </div>
            </div>
          </OverlayWithoutTrigger>
        )
      }
    }
  }

  return info

  // for debug
  // return (
  //   <>
  //     <p>
  //       DEBUG:
  //       <br />
  //       isInApp: {isInApp}
  //       <br />
  //       showUpdateInfo (current version): {showUpdateInfo}
  //       <br />
  //       isAndroid: {isAndroid ? 'true' : 'false'}, isIos:{' '}
  //       {isIOS ? 'true' : 'false'}
  //       <br />
  //       androidTargetVersion: {androidTargetVersion}, iosTargetVersion:{' '}
  //       {iosTargetVersion}
  //       <br />
  //       acknowledged: {acknowledged}
  //     </p>

  //     {info}
  //   </>
  // )
}

export default AppUpdateInfo
