import * as prismic from '@prismicio/client'
import { INavigationItem, INavigationLink } from './types'
import { v4 as uuidv4 } from 'uuid'

const repoId = 'hbdummy'
const accessToken =
  'MC5aa1g2OFJJQUFDQUFRbkRM.77-9AiHvv73vv73vv73vv73vv73vv73vv73vv70D77-977-977-977-977-9bO-_vUjvv73vv71x77-9WkhxLO-_vWBsKw'

const prismicClient = prismic.createClient(repoId, { accessToken })
export default prismicClient

// maps our commonly used "Link" pattern in prismic
const prismicLinkToNavigationLink = async (
  idPrefix: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  link: any,
  urlPrefix?: string
): Promise<INavigationLink> => {
  const item: INavigationLink = {
    id: `${idPrefix}.${link.name ? encodeURI(link.name) : uuidv4()}`,
    name: link.name ? link.name : 'Link',
    title: link.title ? link.title : undefined,
    href: link.link.url,
    target: link.link.target === '_blank' ? '_blank' : undefined,
    action: link.action ? link.action : undefined
  }

  // use the target pages `url_id` for url building, adding urlPrefix for nesting
  if (
    link.link.link_type === 'Document' &&
    link.link.type == 'department_page'
  ) {
    const pageData = await prismicClient.getByID(link.link.id)

    if (pageData.data.url_id) {
      item.href = '/' + [urlPrefix, pageData.data.url_id].join('/')
    } else {
      item.href = '/' + [urlPrefix, pageData.uid].join('/')
    }
  }

  if (link.icon_id) {
    item.icon = {
      id: link.icon_id
    }
  }

  if (link.icon?.url) {
    item.icon = {
      url: link.icon.url
    }
  }

  // console.log('prismicLinkToNavigationLink', item)

  return item
}
export { prismicLinkToNavigationLink }

// allows for `INavigationGroup` or `INavigationLink`
// loads nested links for navigation group on the fly
const prismicLinkToNestedNavigationLink = async (
  idPrefix: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  link: any
): Promise<INavigationItem> => {
  if (link.secondary_navigation_items?.uid) {
    const data = await prismicClient.getByUID(
      'secondary_navigation',
      link.secondary_navigation_items.uid
    )

    // just single nested
    const id = `${idPrefix}.${link.secondary_navigation_items.uid}`
    const item: INavigationItem = {
      id: id,
      name: link.name,
      title: link.title ? link.title : undefined,
      items: [] as INavigationItem[]
    }

    const filledSecondGroup = data.data.second_group_name && data.data.second_group_name !== '' && data.data.second_group_links.filter((link: any) => link.name && link.name !== '').length > 0
    const filledThirdGroup = data.data.third_group_name && data.data.third_group_name !== '' && data.data.third_group_links.filter((link: any) => link.name && link.name !== '').length > 0

    if (filledSecondGroup || filledThirdGroup) {
      // double nested. add `link`, `second_group_link` and `third_group_links`, each with name

      const firstGroupLinks = await Promise.all(
        data.data.links.map(
          async (l: any) => await prismicLinkToNavigationLink(id, l)
        )
      )

      item.items.push({
        id: `${id}.first_group.`,
        name: data.data.name,
        title: data.data.title ? data.data.title : undefined,
        items: firstGroupLinks
      })

      if (filledSecondGroup) {
        const secondGroupLinks = await Promise.all(
          data.data.second_group_links.map(
            async (l: any) => await prismicLinkToNavigationLink(id, l)
          )
        )

        item.items.push({
          id: `${id}.second_group.`,
          name: data.data.second_group_name,
          title: data.data.second_group_title
            ? data.data.second_group_title
            : undefined,
          items: secondGroupLinks
        })
      }

      if (filledThirdGroup) {
        const thirdGroupLinks = await Promise.all(
          data.data.third_group_links.map(
            async (l: any) => await prismicLinkToNavigationLink(id, l)
          )
        )

        item.items.push({
          id: `${id}.third_group.`,
          name: data.data.third_group_name,
          title: data.data.third_group_title
            ? data.data.third_group_title
            : undefined,
          items: thirdGroupLinks
        })
      }

    } else {
      // single nested. just add first `links`
      item.items = await Promise.all(
        data.data.links.map(
          async (l: any) => await prismicLinkToNavigationLink(id, l)
        )
      )
    }

    return item
  }

  return await prismicLinkToNavigationLink(idPrefix, link)
}

export { prismicLinkToNestedNavigationLink }
