import prismicClient, { prismicLinkToNavigationLink } from './prismicClient'
import { INavigationLink } from './types'

const loadMobileTabNavigation = async (): Promise<INavigationLink[]> => {
  const prismicUid = 'mobile_tab_navigation'
  const data = await prismicClient.getSingle(prismicUid)

  const mainShortcuts = await Promise.all(
    data.data.links.map(
      async (link: any) => await prismicLinkToNavigationLink(prismicUid, link)
    )
  )

  return mainShortcuts
}

export default loadMobileTabNavigation
