'use client'

import { useState, createContext, useContext, ReactNode } from 'react'

type MenuContextType = {
  menuOpen: boolean
  openMenu: () => void
  closeMenu: () => void
}

const MenuContext = createContext<MenuContextType | undefined>(undefined)

export const MenuProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const openMenu = () => {
    document.body.classList.add('showing-menu')
    setMenuOpen(true)
  }

  const closeMenu = () => {
    document.body.classList.remove('showing-menu')
    setMenuOpen(false)
  }

  return (
    <MenuContext.Provider value={{ menuOpen, openMenu, closeMenu }}>
      {children}
    </MenuContext.Provider>
  )
}

export const useMenu = (): MenuContextType => {
  const context = useContext(MenuContext)
  if (!context) {
    throw new Error('useMenu must be used within a MenuProvider')
  }
  return context
}
