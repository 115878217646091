import React, { ComponentType } from 'react'
import Link, { LinkProps } from 'components/router/Link'
import Button, { ButtonProps } from 'components/ui/basic/Button'

type LinkButtonProps = { buttonClassName?: string } & LinkProps &
  Omit<ButtonProps, 'onClick'>

const LinkButton: ComponentType<LinkButtonProps> = ({
  children,
  className = '',
  buttonClassName = '',
  color,
  disabled,
  iconOnly,
  small,
  tooltip,
  ...linkProps
}) => (
  <Link {...linkProps} className={className}>
    <Button
      color={color}
      disabled={disabled}
      iconOnly={iconOnly}
      tooltip={tooltip}
      small={small}
      className={buttonClassName}
    >
      {children}
    </Button>
  </Link>
)

export default LinkButton
