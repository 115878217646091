import * as querystring from 'querystring'
import { apiBaseUrl } from 'api/helpers'
import { notFound } from 'next/navigation'

const loadJson = async (url: string): Promise<any> => {
  const response = await fetch(url, { next: { revalidate: 10 } })
  return response.json()
}

export const loadInternalJson = async (
  url: string,
  params = {}
): Promise<any> => {
  const queryParams = querystring.stringify(params)
  const res = await loadJson(`${apiBaseUrl}/sportdata/1${url}?${queryParams}`)
  if (res.code === 404) {
    notFound()
  }
  return res
}

export default loadJson
