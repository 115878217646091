'use client'

import React, { ComponentType, useEffect, useState } from 'react'
import { ChevronUp } from 'react-feather'

const ScrollToTop: ComponentType = () => {
  const [isVisible, setIsVisible] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', toggleVisibility)

    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  if (!isVisible) return null

  return (
    <div className="scroll-to-top" onClick={scrollToTop}>
      <ChevronUp className="scroll-to-top-icon" />
    </div>
  )
}

export default ScrollToTop
