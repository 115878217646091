import { imagePath } from 'shared/urlHelpers'
import { baseImagePath } from 'api/helpers'

const apiImageStartConstant = 'handball-net:'

export const parseImageUrl = (url: string): string =>
  url.replace(apiImageStartConstant, `${baseImagePath}/`)

const checkLogo = (
  image?: string,
  type?: 'tournament' | 'club' | 'field'
): string => {
  if (image?.startsWith(apiImageStartConstant)) return parseImageUrl(image)
  return (
    image ||
    imagePath(
      type ? `logo-placeholder-${type}.svg` : 'logo-placeholder-club.svg',
      'placeholders'
    )
  )
}

export const checkPlayerImage = (image?: string): string => {
  if (image?.startsWith(apiImageStartConstant)) return parseImageUrl(image)
  return image || imagePath('player-placeholder.svg', 'placeholders')
}

export const checkImage = (image?: string): string => {
  if (image?.startsWith(apiImageStartConstant)) return parseImageUrl(image)
  return image || imagePath('image-placeholder.jpg', 'placeholders')
}

export const checkuserImage = (image?: string): string => {
  if (image?.startsWith(apiImageStartConstant)) return parseImageUrl(image)
  return image
    ? imagePath(image)
    : imagePath('image-placeholder.jpg', 'placeholders')
}

export default checkLogo
