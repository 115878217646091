import prismicClient, {
  prismicLinkToNavigationLink,
  prismicLinkToNestedNavigationLink
} from './prismicClient'
import { IMainNavigation } from './types'

const loadMainNavigation = async (): Promise<IMainNavigation> => {
  const prismicUid = 'navigation'
  const data = await prismicClient.getSingle(prismicUid)

  const navigation = {
    main_items: await Promise.all(
      data.data.links.map(
        async (link: any) =>
          await prismicLinkToNavigationLink('navigation.main', link)
      )
    ),
    secondary_items: await Promise.all(
      data.data.secondary_navigation.map(
        async (link: any) =>
          await prismicLinkToNestedNavigationLink('navigation.secondary', link)
      )
    ),
    info_items: await Promise.all(
      data.data.info_navigation.map(
        async (link: any) =>
          await prismicLinkToNestedNavigationLink('navigation.info', link)
      )
    ),
    social_items: await Promise.all(
      data.data.icons.map(
        async (link: any) =>
          await prismicLinkToNavigationLink('navigation.social', link)
      )
    )
  }

  return navigation
}

export default loadMainNavigation
