import { ApiResponse, FetchResponse, ParsedResponse } from 'data/authentication'
import Bugsnag from '@bugsnag/js'
import Error from 'next/error'

function getUnknownErrorResponse<T> (
  parsedResponse: T | null
): ParsedResponse<T> {
  return {
    result: {
      valid: false,
      errors: [
        { field: 'base', message: 'Es ist ein unbekanner Fehler aufgetreten!' }
      ]
    },
    response: parsedResponse
  }
}

export async function handleResponse<T> (
  resp: FetchResponse
): Promise<ParsedResponse<T>> {
  const json = await resp.json()
  const getParsedResponse = (result?: ApiResponse): ParsedResponse<T> => ({
    result,
    response: json
  })
  if (resp.ok) return getParsedResponse({ valid: true })
  if (resp.status === 500) {
    Bugsnag.notify(`Error Response: ${JSON.stringify(json)}`)
    return getUnknownErrorResponse(json)
  } else if (resp.status === 422) {
    return getParsedResponse({ valid: false, errors: json.errors })
  }
  return getParsedResponse({
    valid: false,
    errors: [
      {
        field: 'base',
        message: json.message
      }
    ]
  })
}

export function handleError<T> (error: Error): ParsedResponse<T> {
  Bugsnag.notify(`Error: ${error.toString()}`)
  // @ts-ignore
  return getUnknownErrorResponse(null)
}
