import { ComponentType } from 'react'
import { NewTabNavigationItem } from './NewTabNavigationItem'
import { loadAppConfig } from 'data/config/loadAppConfig'

// TODO : check if the route is active (prefix probably), if we need it at all (navi is probably hidden anyway)
export const routeActive = (href: string): boolean => {
  return false
}

const TabBar: ComponentType = async () => {
  const { mobile_tab_navigation } = await loadAppConfig()

  return (
    <>
      <nav className="fixed bottom-0 left-0 right-0 h-[var(--tabBarHeight)] z-40 bg-white/[.97] backdrop-blur-sm tab-bar lg:hidden">
        <ul className="flex justify-between px-4 sm:px-8 gap-0.5 -mb-4">
          {mobile_tab_navigation.map((item) => (
            <NewTabNavigationItem
              key={`tab_navigation.${item.id}`}
              item={item}
            />
          ))}
        </ul>
      </nav>
    </>
  )
}

export default TabBar
