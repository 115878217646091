import prismicClient, { prismicLinkToNavigationLink } from './prismicClient'
import { INavigationLink } from './types'

const loadMainShortcuts = async (): Promise<INavigationLink[]> => {
  const prismicUid = 'main_shortcuts'
  const data = await prismicClient.getSingle(prismicUid)

  const mainShortcuts = await Promise.all(
    data.data.links.map(
      async (link: any) => await prismicLinkToNavigationLink(prismicUid, link)
    )
  )

  return mainShortcuts
}

export default loadMainShortcuts
