'use client'

import { ReactNode, createContext, useContext } from 'react'
import { IAppConfig } from './types'

const AppConfigContext = createContext<IAppConfig | null>(null)

const useAppConfig = (): IAppConfig => {
  const appConfigContext = useContext(AppConfigContext)

  if (!appConfigContext) {
    throw new Error('useAppConfig has to be used within <AppConfigProvider>')
  }

  return appConfigContext
}

const AppConfigProvider = ({
  value,
  children
}: {
  value: IAppConfig
  children: ReactNode
}): ReactNode => {
  return (
    <AppConfigContext.Provider value={value}>
      {children}
    </AppConfigContext.Provider>
  )
}

export { AppConfigContext, AppConfigProvider, useAppConfig }
