import querystring from 'querystring'

const prepareClientCall = ({
  token,
  queryParams
}: {
  token?: string
  queryParams: Record<string, any>
}) => {
  // eslint-disable-next-line no-undef
  const headers = new Headers()

  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  return {
    headers,
    queryParams: querystring.stringify(queryParams)
  }
}

export const clientGET = async ({
  url,
  token,
  params = {}
}: {
  url: string
  token?: string
  params?: Record<string, any>
}): Promise<any> => {
  const { headers, queryParams } = prepareClientCall({
    queryParams: params,
    token
  })
  // eslint-disable-next-line no-undef
  const response = await fetch(`/a${url}?${queryParams}`, {
    method: 'GET',
    headers,
    credentials: 'include'
  })
  return response.json()
}

export const clientHEAD = async ({
  url,
  token,
  params = {}
}: {
  url: string
  token?: string
  params?: Record<string, any>
}): Promise<number> => {
  const { headers, queryParams } = prepareClientCall({
    token,
    queryParams: params
  })
  // eslint-disable-next-line no-undef
  const response = await fetch(`/a${url}?${queryParams}`, {
    method: 'HEAD',
    headers,
    credentials: 'include'
  })
  return response.status
}

export const clientPOST = async ({
  url,
  data,
  token,
  params = {}
}: {
  url: string
  data: Record<string, any>
  token?: string
  params?: Record<string, any>
}): Promise<Record<string, any>> => {
  const { headers, queryParams } = prepareClientCall({
    token,
    queryParams: params
  })
  headers.set('Content-Type', 'application/json')
  // eslint-disable-next-line no-undef
  const response = await fetch(`/a${url}?${queryParams}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers,
    credentials: 'include'
  })
  if (response.status === 204) {
    return {}
  }
  return response.json()
}

export const clientPUT = async ({
  url,
  data,
  token,
  params = {}
}: {
  url: string
  data: Record<string, any>
  token?: string
  params?: Record<string, any>
}): Promise<Record<string, any>> => {
  const { headers, queryParams } = prepareClientCall({
    token,
    queryParams: params
  })
  headers.set('Content-Type', 'application/json')
  // eslint-disable-next-line no-undef
  const response = await fetch(`/a${url}?${queryParams}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers,
    credentials: 'include'
  })
  if (response.status === 204) {
    return {}
  } else {
    return response.json()
  }
}

export const clientDELETE = async ({
  url,
  token,
  params = {}
}: {
  url: string
  token?: string
  params?: Record<string, any>
}): Promise<any> => {
  const { headers, queryParams } = prepareClientCall({
    token,
    queryParams: params
  })
  // eslint-disable-next-line no-undef
  const response = await fetch(`/a${url}?${queryParams}`, {
    method: 'DELETE',
    headers,
    credentials: 'include'
  })
  return response
}
