import { ReactElement } from 'react'
// import HandballHLogo from 'public/img/logo-h.svg'
import Link from 'next/link'
import { loginPath } from 'shared/urlHelpers'

interface HBButtonProps {
  children?: React.ReactNode
  className?: string
}

const HBButton = ({ className, children }: HBButtonProps): ReactElement => {
  return (
    <Link
      href={loginPath()}
      className={`btn btn-hbnet relative !m-0 ${className}`}
    >
      {children}
      {/* <div className="absolute right-0 top-0 bottom-0 border-l border-l-white w-10 items-center justify-center btn-hbnet__logo">
        <HandballHLogo className="!w-6 !h-6" />
      </div> */}
    </Link>
  )
}

export default HBButton
