import {
  SearchParams,
  AutocompleteSuggestionData,
  SearchResultsData
} from 'api/types'
import { clientGET, clientPOST } from 'api/client'
import { loadInternalJson } from 'data/loadJson'

const searchApiBase = '/sportdata/1'

export const getFieldsSearch = async (
  params: SearchParams = {}
): Promise<SearchResultsData> => {
  return loadInternalJson('/fields/search', params)
}

export const getTournamentsSearch = async (
  params: SearchParams = {}
): Promise<SearchResultsData> => {
  return loadInternalJson('/tournaments/search', params)
}

export const getClubsSearch = async (
  params: SearchParams = {}
): Promise<SearchResultsData> => {
  return loadInternalJson('/clubs/search', params)
}

export const getAutocompleteSuggestions = async (
  params: SearchParams = {}
): Promise<AutocompleteSuggestionData> => {
  return clientGET({
    url: searchApiBase + '/search/autocomplete_suggestions',
    params
  })
}

export const getTeamsSearch = async (
  params: SearchParams = {}
): Promise<SearchResultsData> => {
  return clientGET({
    url: searchApiBase + '/teams/search',
    params
  })
}

export type TrackSearchClickData = {
  clickedResultTab?: string
  clickedResultType?: string
  clickedResultId?: string
  clickedResultUrl?: string
}

export type TrackSearchResultData = {
  countResultsClubs?: number
  countResultsTournaments?: number
  countResultsVenues?: number
  countResultsTeams?: number
}

export type TrackSearchData = {
  eventType: 'search' | 'result' | 'click' | 'error'
  searchSessionUid: string
  searchTerm: string
} & TrackSearchResultData &
  TrackSearchClickData

export const trackSearch = async function (
  data: TrackSearchData
): Promise<Record<string, unknown>> {
  return await clientPOST({
    url: '/myhandball/1/track/search',
    data
  })
}
